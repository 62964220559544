import React, { Component } from "react";
import Sublime from './Sublime';
import "../components/AboutMe.css";

class AboutMe extends Component {
  render() {
    return (
      <div
        className="jumbotron jumbotron-fluid aboutMe"
        ref={this.props.aboutMeRefProp}
      >
        <div className="container content aboutme-content">
          <div className="text-center">
            <p>ABOUT ME</p>
            <hr className="aboutme-divider" />
          </div>
          <div className="row">
            <div className="col aboutme-summary-wrapper">
              <div className="aboutme-summary">
                <ul>
                  {this.props.dataProp.bullets.map((bullet, index) => {
                    return <li key={index}>{bullet}</li>;
                  })}
                </ul>
              </div>
              <div className="aboutme-summary">
                <ul className="aboutme-summary-desc">
                  {this.props.dataProp.summary.map((text, index) => {
                    return <li key={index}>{text}</li>;
                  })}
                </ul>
              </div>
            </div>
            <div />
            <div className="col sublime">
            <div className="row rowmartop"></div>
              {"{"}
              <Sublime dataProp={this.props.dataProp.jsonData}/>
              {"}"}
              <div className="row rowmarbottom"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutMe;
