import React, { Component } from "react";
import { NavBar, Content, AboutMe, Contact } from "./components/Components";
import data from './config/data';

class App extends Component {
  constructor(props) {
    super(props);
    this.aboutMeRef = React.createRef();
    this.contactMeRef = React.createRef();
  }

  scroll(ref) {
    window.scrollTo({
      top: ref ? ref.current.offsetTop : "",
      behavior: "auto"
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <NavBar
          scrollRef={this.scroll}
          aboutMeProp={this.aboutMeRef}
          contactProp={this.contactMeRef}
          dataProp={data.portfolioData.navbar}
        />
        <Content dataProp={data.portfolioData.content}/>
        <AboutMe dataProp={data.portfolioData.aboutMe} aboutMeRefProp={this.aboutMeRef} />
        <Contact dataProp={data.portfolioData.contact} contactMeRefProp={this.contactMeRef} />
      </div>
    );
  }
}

export default App;
