import React, { Component } from "react";
import "../components/NavBar.css";

class NavBar extends Component {
  scrollTo(propNameForElement) {
    this.props.scrollRef(this.props[propNameForElement]);
  }

  createPropName(itemName) {
    let items = itemName.split(" ");
    if (items.length === 1) {
      return itemName.toLowerCase() + "Prop";
    } else {
      let propName = "";
      items.forEach((name, index) => {
        if (index === 0) {
          propName = propName + name.toLowerCase();
        } else {
          propName = propName + name.charAt(0).toUpperCase() + name.slice(1);
        }
      });
      return propName + "Prop";
    }
  }
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-fixed-top navbar-light">
          <div className="container">
            <div id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <a
                    className="navbar-brand"
                    href="/"
                    aria-label="Hemanth Vakulabharanam"
                  >
                    {this.props.dataProp.name}
                  </a>
                </li>
                {this.props.dataProp.socialLinks.map((item, index) => {
                  return (
                    <li className="nav-item d-none d-sm-block" key={index}>
                      <a
                        className="btn btn-social-icon"
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Shortcut for hemanth vakulabharanam's linkedin profile"
                      >
                        <i className={`fab ${item.icon}`} />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                {this.props.dataProp.navItems.map((item, index) => {
                  return (
                    <li className="nav-item" key={index}>
                      <button
                        className="nav-link buttonLink"
                        onClick={() => this.scrollTo(this.createPropName(item))}
                      >
                        {item}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </nav>
        <div className="container social-icons d-sm-block d-md-none">
          {this.props.dataProp.socialLinks.map((item, index) => {
            return (
              <a
                className="btn btn-social-icon"
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Shortcut for hemanth vakulabharanam's linkedin profile"
                key={index}
              >
                <i className={`fab ${item.icon}`} />
              </a>
            );
          })}
        </div>
      </div>
    );
  }
}

export default NavBar;
