import React, { Component } from "react";
import "../components/Sublime.css";

class Sublime extends Component {
  render() {
    let childNodes = "";
    return this.props.dataProp.map((item, index) => {
      if (item.children) {
        childNodes = (
          <div>
            <Sublime dataProp={item.children} />
          </div>
        );
      }

      return (
        <div className="row" key={index}>
          <div className="col-11 offset-1">
            {index !== this.props.dataProp.length - 1 ? (
              item.value !== "" ? (
                <span>
                  "{item.title}": "{item.value}",
                </span>
              ) : (
                <span>
                  "{item.title}": {"{"}
                  {childNodes}
                  {"},"}
                </span>
              )
            ) : item.value !== "" ? (
              item.title === "Email" ? (
                <div className="emailWrapper">
                  "{item.title}":
                  <a
                    className="aboutme-email"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={"mailto: " + item.value}
                  >
                    &nbsp;"{item.value}"
                  </a>
                </div>
              ) : (
                <span>
                  "{item.title}": "{item.value}"
                </span>
              )
            ) : (
              <span>
                "{item.title}": {"{"}
                {childNodes}
                {"}"}
              </span>
            )}
          </div>
        </div>
      );
    });
  }
}

export default Sublime;
