export default {
    "portfolioData": {
        "navbar": {
            "name": "Hemanth Vakulabharananam",
            "navItems": [
                "About Me",
                "Contact"
            ],
            "socialLinks": [
                {
                    "url": "https://www.linkedin.com/in/hemanthvakulabharanam/",
                    "icon": "fa-linkedin-in"
                },
                {
                    "url": "https://www.github.com",
                    "icon": "fa-github"
                },
            ],
            "linkedInUrl": "https://www.linkedin.com/in/hemanthvakulabharanam/",
            "githubUrl": "https://www.github.com",
        },
        "content": {
            "name": "Hemanth Vakulabharanam",
            "roles": [
                "FULL STACK DEVELOPER, ASPIRING DATA SCIENTIST"
            ]
        },
        "aboutMe": {
            "bullets": [
                "2.5 Years of experience as software engineer",
                "Proficient in full-stack development",
                "Worked in teams ranging from 1 to 20 people",
                "Quick learner and team player",
                "Exposed to all areas of SDLC",
                "Interested in cloud technologies like AWS",
                "Always up for challenges",
                "Problem Solver"
            ],
            "summary": [
                "Currently working on my Master's with a specialization in Data Science. Recently, I have been looking into big data and machine learning applications."
            ],
            "jsonData": [
                {
                    "title": "Education",
                    "value": "The University of Texas at Dallas"
                },
                {
                    "title": "Major",
                    "value": "Computer Science"
                },
                {
                    "title": "Expected Graduation",
                    "value": "December 2019"
                },
                {
                    "title": "Work Experience",
                    "value": "",
                    "children": [
                        {
                            "title": "Latest",
                            "value": "",
                            "children": [
                                {
                                    "title": "Company",
                                    "value": "Hoonuit"
                                },{
                                    "title": "Role",
                                    "value": "Software Engineering Intern"
                                },{
                                    "title": "Period",
                                    "value": "Aug 2018 - Jan 2019"
                                }
                            ]
                        },
                        {
                            "title": "Previous",
                            "value": "",
                            "children": [
                                {
                                    "title": "Company",
                                    "value": "Amdocs"
                                },{
                                    "title": "Role",
                                    "value": "Software Developer"
                                },{
                                    "title": "Period",
                                    "value": "Jul 2015 - Jun 2017"
                                }
                            ]
                        },
                    ]
                },
                {
                    "title": "Email",
                    "value": "hemanth.vakulabharanam@utdallas.edu"
                }
            ]
        },
        "contact": {
            "title": "CONTACT ME",
            "forms": [
                "Email Address",
                "Name",
                "Message"
            ]
        },
    }
}