import React, { Component } from "react";
import "../components/Content.css";
import Picture from "../images/skype_profile.jpg";
import Typed from "typed.js";

class Content extends Component {
  componentDidMount() {
    const strings = this.props.dataProp.roles;
    const options = {
      strings: strings,
      typeSpeed: 80,
      backSpeed: 100
    };
    this.typed = new Typed(this.el, options);
  }

  componentWillUnmount() {
    this.typed.destroy();
  }

  render() {
    return (
      <div className="jumbotron jumbotron-fluid">
        <div className="container content">
          <div className="text-center">
            <img src={Picture} alt="Profile" />
            <p>{this.props.dataProp.name}</p>
            <hr />
            <div className="typewriter">
              <span
                ref={el => {
                  this.el = el;
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
