import React, { Component } from "react";
import axios from "axios";
import "../components/Contact.css";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      message: "",
      showSuccessMsg: false
    };
  }

  handleForm = e => {
    var self = this;
    axios
      .post("https://formcarry.com/s/BGSRJMDXva4", this.state, {
        headers: { Accept: "application/json" }
      })
      .then(function(response) {
        console.log(response);
        if (response.data.code === 200 && response.data.status === "success") {
            self.setState({
                email: "",
                name: "",
                message: "",
                showSuccessMsg: true
            });
            setTimeout(() => {
                self.setState({
                    showSuccessMsg: false
                })
            }, 7000);
        }
      })
      .catch(function(error) {
        console.log(error);
      });

    e.preventDefault();
  };

  handleFields = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div
        className="jumbotron jumbotron-fluid contact"
        ref={this.props.contactMeRefProp}
      >
        <div className="container">
          <div className="content">
            <div className="text-center">
              <p>{this.props.dataProp.title}</p>
              <hr className="contact-divider" />
            </div>
          </div>
          {
              this.state.showSuccessMsg ? 
              <div className="alert alert-success col-md-4 offset-md-4 col-sm-8 text-center" role="alert">
                Thank you for reaching out. I'll contact you as soon as possible.
              </div> :
              ""
          }
          <form
            onSubmit={this.handleForm}
            className="col-md-8 offset-md-2 col-sm-10"
          >
            <div className="form-group">
              <label htmlFor="email">{this.props.dataProp.forms[0]}</label>
              <input
                type="email"
                className="form-control"
                id="email"
                aria-describedby="email"
                placeholder="Enter email"
                name="email"
                value = {this.state.email}
                onChange={this.handleFields}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">{this.props.dataProp.forms[1]}</label>
              <input
                type="text"
                className="form-control"
                id="name"
                aria-describedby="name"
                placeholder="Enter name"
                name="name"
                value = {this.state.name}
                onChange={this.handleFields}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">{this.props.dataProp.forms[2]}</label>
              <textarea
                type="text"
                className="form-control"
                id="message"
                aria-describedby="message"
                placeholder="Enter message"
                name="message"
                value = {this.state.message}
                onChange={this.handleFields}
              />
            </div>
            <button type="submit" className="btn btn-primary submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default Contact;
